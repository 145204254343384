
import { computed, defineComponent } from '@nuxtjs/composition-api';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Object, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    reduce: {
      type: Function,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const modelValue = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      },
    });

    return {
      modelValue,
    };
  },
});
