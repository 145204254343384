import { computed, ref, useContext, useStore } from '@nuxtjs/composition-api';

const FORM_STATIC_PARAMS = {
  site: 'Lorgar EU',
};

const useForm = (fields) => {
  const {
    i18n,
    $api: { formsService },
    $axios,
  } = useContext();
  const store = useStore();
  const defaultLocale = store?.getters['config/getDefaultLanguage']?.code;
  const isFormPending = ref(false);

  const currentLocaleISO = computed(
    () =>
      i18n.locales.find(
        (locale) => locale?.code === (i18n.locale || defaultLocale)
      )?.iso
  );

  const formFields = ref({
    ...fields,
    ...FORM_STATIC_PARAMS,
    lang: currentLocaleISO.value ?? 'en',
  });

  const submit = async (formData, formId) => {
    isFormPending.value = true;
    if (!formId) await formsService.sendFormData(formData);
    await $axios.post(`/api/forms/${formId}`, formData);
    isFormPending.value = false;
  };

  const resetForm = () => {
    formFields.value = {
      ...fields,
      ...FORM_STATIC_PARAMS,
      lang: currentLocaleISO.value,
    };
  };

  return {
    formFields,
    submit,
    resetForm,
    isFormPending,
  };
};

export default useForm;
