import { render, staticRenderFns } from "./ServiceForm.vue?vue&type=template&id=1e112e43"
import script from "./ServiceForm.vue?vue&type=script&setup=true&lang=js"
export * from "./ServiceForm.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ServiceForm.vue?vue&type=style&index=0&id=1e112e43&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIInput: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIInput.vue').default,UISelect: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UISelect.vue').default,UIAutocomplete: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIAutocomplete.vue').default,UITextarea: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UITextarea.vue').default,UICheckbox: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UICheckbox.vue').default,UiRecaptcha: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UiRecaptcha.vue').default,ServiceFormProvider: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/ServiceFormProvider.vue').default})
