import { render, staticRenderFns } from "./UIAutocomplete.vue?vue&type=template&id=1c22f946&scoped=true"
import script from "./UIAutocomplete.vue?vue&type=script&setup=true&lang=ts"
export * from "./UIAutocomplete.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./UIAutocomplete.vue?vue&type=style&index=0&id=1c22f946&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c22f946",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIIcon.vue').default,UiSpinnerCircle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UiSpinnerCircle.vue').default})
