
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    rows: {
      type: String,
      default: '4',
    },
    error: {
      type: String,
      default: null,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    maxCount: {
      type: [String, Number],
      default: 10,
    },
  },
  setup(props, { emit }) {
    const modelValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit('input', newValue);
      },
    });
    const remainingCount = computed(() => {
      if (props.maxCount - modelValue.value.length <= 0) {
        return 0;
      }

      return props.maxCount - modelValue.value.length;
    });

    return {
      modelValue,
      remainingCount,
    };
  },
});
