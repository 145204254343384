
import { defineComponent, inject, computed } from '@nuxtjs/composition-api';
import VueRecaptcha from 'vue-recaptcha';

export default defineComponent({
  components: { VueRecaptcha },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const recaptchaError = inject('recaptchaError');

    const valueLocal = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        // eslint-disable-next-line vue/require-explicit-emits
        emit('input', newValue);
      },
    });

    const verifyCaptcha = (token) => {
      valueLocal.value = token;
    };

    const expiredCaptcha = () => {
      valueLocal.value = null;
    };

    return {
      recaptchaError,
      valueLocal,
      verifyCaptcha,
      expiredCaptcha,
    };
  },
});
