import {
  reactive,
  ref,
  useStore,
  useContext,
  watch,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';

import { AbButton } from '@webplatform/asbis-ui';
import useForm from '~/composables/useForm';
import useFetchCategory from '~/composables/useFetchCategory';
import UIInput from '~/components/ui/UIInput.vue';
import UISelect from '~/components/ui/UISelect.vue';
import UICheckbox from '~/components/ui/UICheckbox.vue';
import UIAutocomplete from '~/components/ui/UIAutocomplete.vue';
import ServiceFormProvider from './ServiceFormProvider.vue';


export default {
  __name: 'ServiceForm',
  emits: ['success-data-send'],
  setup(__props, { emit }) {


const store = useStore();
const config = store.getters['config/getConfig'];
const lang = store.getters['config/getActiveLanguage'];
const form = ref();
const { submit, isFormPending } = useForm({
  confirm: false,
});
// @ts-ignore

const { nuxtState } = useContext();

const {
  category,
  fetchCategoryBySlug: fetchCategory,
  fetchCategories,
  allCategories: categoriesList,
} = useFetchCategory();
const isMounted = ref(false);

const DEFAULT_FORM_DATA = {
  site: config.domain,
  lang,
  name: '',
  email: '',
  phone: '',
  category: '',
  sn: '',
  model: undefined,
  purchaseDate: '',
  deliveryAgent: undefined,
  country: '',
  buildingNumber: '',
  city: '',
  apartmentNumber: '',
  street: '',
  postalCode: '',
  faultDescription: '',
  personalData: false,
  fullAddress: '',
  state: '',
  token: '',
};

const formData = reactive({
  ...DEFAULT_FORM_DATA,
});

const clearForm = () => {
  Object.assign(formData, DEFAULT_FORM_DATA);
  form.value?.reset();
};

const categories = computed(() =>
  categoriesList.value.map((categoryItem) => ({
    id: categoryItem.slug,
    name: categoryItem.name,
  }))
);
const models = computed(() => {
  return category.value?.products?.map((product) => ({
    id: product.id,
    name: product.name,
  }));
});

const searchedAddress = ref('');
const autocompleteRef = ref();
const serviceFormProviderRef = ref();

const isFormFieldAvailable = (fieldName) => {
  const availableFormFields =
    serviceFormProviderRef.value.getAvailableFormFields();
  return availableFormFields.includes(fieldName);
};

const handleSelectCategory = async (categoryName) => {
  await fetchCategory(categoryName, { limit: 10 });
};

const createFormPayload = (formState) => ({
  name: formState.name,
  phone: formState.phone,
  email: formState.email,
  delivery_agent: formState.deliveryAgent,
  country: formState.country,
  zipcode_or_street: formState.fullAddress,
  category: formState.category,
  model: formState.model,
  serial_number: formState.sn,
  purchase_date: formState.purchaseDate,
  fault_description: formState.faultDescription,
  сonsent_agreement: formState.personalData,
});
const submitForm = async () => {
  try {
    const formId = nuxtState.config.serviceFormUuid;
    const resultFormData = {
      ...createFormPayload(formData),
      page_url: window.location.href,
    };

    await submit(resultFormData, formId);
    emit('success-data-send');
    clearForm();
  } catch (error) {
    console.error('Error submitting form', error);
  }
};

const handleSelectCountry = async (countryName) => {
  if (formData.deliveryAgent === 'dpd') {
    serviceFormProviderRef.value.dpd.getDpdCitiesBySelectedCountry(countryName);
  }

  formData.city = '';
  formData.buildingNumber = '';
  formData.apartmentNumber = '';
  formData.street = '';
  formData.postalCode = '';
  formData.fullAddress = '';
  formData.state = '';
  searchedAddress.value = '';
};

const searchAddress = (event) => {
  searchedAddress.value = event.query;
  if (formData.deliveryAgent !== 'dpd') return;

  if (!searchedAddress.value) {
    serviceFormProviderRef.value.setSuggestions();
    return;
  }
  const dpdLocationsFromProvider =
    serviceFormProviderRef.value.getLocations('dpd');
  const suggestions = dpdLocationsFromProvider.filter((dpdAddress) => {
    const fullAddress = `${dpdAddress.city}, ${dpdAddress.postcode}, ${dpdAddress.street} ${dpdAddress.house_number}`;
    return fullAddress
      .toLowerCase()
      .includes(searchedAddress.value.toLowerCase().trim());
  });
  serviceFormProviderRef.value.setSuggestions(suggestions);
};
const handleSelectAddress = (event) => {
  const fullAddress = `${event.value.city}, ${event.value.postcode}, ${event.value.street} ${event.value.house_number}`;
  searchedAddress.value = fullAddress;
  formData.fullAddress = fullAddress;
  if (formData.deliveryAgent === 'dpd') formData.city = event.value.city;
};
const handleBlurAddress = () => {
  if (searchedAddress.value && !formData.fullAddress)
    searchedAddress.value = formData.fullAddress;
};

const clearDeliviryFields = () => {
  formData.country = '';
  formData.city = '';
  formData.buildingNumber = '';
  formData.apartmentNumber = '';
  formData.street = '';
  formData.postalCode = '';
  formData.fullAddress = '';
  formData.state = '';
};
const isSubmitDisabled = (invalid) => {
  return invalid || isFormPending.value;
};
const handleFocusAutocomplete = () => {
  if (formData.deliveryAgent === 'ups')
    autocompleteRef.value.$refs.autocomplete.showOverlay();
};
const handleUpsLocationWasSearched = () => {
  searchedAddress.value = '';
  formData.fullAddress = '';
};
watch(
  () => formData.deliveryAgent,
  () => {
    clearDeliviryFields();
    searchedAddress.value = '';
  }
);

onMounted(() => {
  isMounted.value = true;
  fetchCategories();
});

return { __sfc: true,emit, store, config, lang, form, submit, isFormPending, nuxtState, category, fetchCategory, fetchCategories, categoriesList, isMounted, DEFAULT_FORM_DATA, formData, clearForm, categories, models, searchedAddress, autocompleteRef, serviceFormProviderRef, isFormFieldAvailable, handleSelectCategory, createFormPayload, submitForm, handleSelectCountry, searchAddress, handleSelectAddress, handleBlurAddress, clearDeliviryFields, isSubmitDisabled, handleFocusAutocomplete, handleUpsLocationWasSearched, AbButton, UIInput, UISelect, UICheckbox, UIAutocomplete, ServiceFormProvider }
}

}