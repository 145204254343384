import {
  onBeforeMount,
  ref,
  ssrRef,
  useContext,
} from '@nuxtjs/composition-api';

const useFetchCategory = () => {
  const PAGE_MAX_PRODUCTS = 12;
  const {
    error: showErrorPage,
    ssrContext,
    nuxtState,
    $api: { categoriesService },
  } = useContext();
  const isLoading = ref(false);
  const allCategories = ref([]);
  const currentCategory = ref({});
  const category = ref(null);
  const error = ssrRef('false', 'error');

  const fetchCategories = async (queryParams) => {
    const { data } = await categoriesService.getAllCategories({
      ...queryParams,
    });

    allCategories.value = data.map((i) => ({
      ...i,
      label: i.name,
      value: i.slug,
    }));
  };

  const setDefaultCategory = () => {
    currentCategory.value = { ...allCategories.value[0] };
  };

  const fetchCategoryBySlug = async (slug, queryParams) => {
    isLoading.value = true;
    try {
      const { data } = await categoriesService.getCategoryBySlug(slug, {
        limit: PAGE_MAX_PRODUCTS,
        'order[popular]': 'desc',
        ...queryParams,
      });

      category.value = data;
    } catch (_) {
      if (process.server) {
        ssrContext.res.statusCode = 404;
        error.value = 'true';
      } else showErrorPage({ statusCode: 404 });
    } finally {
      isLoading.value = false;
    }
  };

  onBeforeMount(() => {
    if (error.value === 'true') {
      showErrorPage({ statusCode: 404 });
      nuxtState.ssrRefs.error = 'false';
    }
  });

  return {
    PAGE_MAX_PRODUCTS,
    category,
    isLoading,
    allCategories,
    currentCategory,
    fetchCategoryBySlug,
    fetchCategories,
    setDefaultCategory,
  };
};

export default useFetchCategory;
