
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'UICheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    iconOn: {
      type: String,
      default: 'checkbox-on',
    },
    iconOff: {
      type: String,
      default: 'checkbox-off',
    },
    name: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const modelValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        if (props.multiple) {
          emit('input', {
            isChecked: newValue,
            value: props.item,
          });
        } else {
          emit('input', newValue);
        }
      },
    });

    return {
      modelValue,
    };
  },
});
